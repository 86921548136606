import React from 'react';
import CKEditor from 'ckeditor4-react';
import { connect } from 'react-redux';
import CONFIG from '../../config';
import {
    toggleCkEditorContentChangedFlag
}
    from '../../actions';
class TextEditor extends React.Component {

    state = {
        data: ""
    }
    handleChange = (event) => {
        const { emailType, storeKey, recordId, isClone } = this.props;
        if (storeKey === "dynamicEmailTemplate" && (emailType === CONFIG.emailTemplateEventType.manualBuildAvailable || emailType === CONFIG.emailTemplateEventType.demoRequestCompleteUser || emailType === CONFIG.emailTemplateEventType.maintenanceUpdate || emailType === CONFIG.emailTemplateEventType.maintenanceCreate)) {
            this.props.toggleCkEditorContentChangedFlag(true);
        }
        const that = this;
        const data = event.editor.getData();

        if (data.indexOf('blob:http') >= 0) {
            const interval = setInterval(function () {
                const newData = event.editor.getData();
                that.props.componentKey && recordId !== undefined
                    ? that.props.updateEditorContent(that.props.componentKey, recordId, newData, isClone)
                    : that.props.componentKey
                        ? that.props.updateEditorContent(that.props.componentKey, newData)
                        : that.props.updateEditorContent(newData)
                if (newData.indexOf('blob:http') < 0) {
                    clearInterval(interval);
                }
            }, 500);

        }
        const newData = event.editor.getData();
        that.props.componentKey && recordId !== undefined
            ? that.props.updateEditorContent(that.props.componentKey, recordId, newData, isClone)
            : that.props.componentKey
                ? that.props.updateEditorContent(that.props.componentKey, newData)
                : that.props.updateEditorContent(newData)

        /*  
            This logic is added to hightlight only those placeholders which are associated to respective email templates when it is copied from one 
            other email templates
        */
        const placeHolders = this.props.placeholders ? this.props.placeholders.map((item) => item.placeholder) : [];
        var placeholderslistInHtml = event.editor.document?.$.body?.getElementsByClassName('cke_placeholder');

        placeholderslistInHtml !== undefined && Array.from(placeholderslistInHtml).forEach(placeholderHtml => {
            const placeholder = placeholderHtml.innerText.replace(/\[|\]/gi, "");
            return !placeHolders.includes(placeholder) ? placeholderHtml.removeAttribute("class") : undefined;

        });

    }

    handleInstanceReady = event => {
        const document = this.CKEDITOR.document.$;
        if (document.getElementsByClassName('cke_button cke_button__createplaceholder')[0]) {
            document.getElementsByClassName('cke_button cke_button__createplaceholder')[0].style.display = "none"
        }

        var itemTemplate = '<li data-id="{id}">' +
            '<div><strong class="item-title">{placeholder}</strong></div>' +
            '</li>',
            outputTemplate = '[[{placeholder}]]<span>&nbsp;</span>';

        var autocomplete = new this.CKEDITOR.plugins.autocomplete(event.editor, {
            textTestCallback: this.textTestCallback,
            dataCallback: this.dataCallback,
            itemTemplate: itemTemplate,
            outputTemplate: outputTemplate
        });

        if (autocomplete) {
            autocomplete.getHtmlToInsert = function (item) {
                return this.outputTemplate.output(item);
            }
        }
    }

    textTestCallback = (range) => {
        if (!range.collapsed) {
            return null;
        }
        return this.CKEDITOR.plugins.textMatch.match(range, this.matchCallback);
    }

    matchCallback = (text, offset) => {
        var pattern = /\[{2}([A-z]|\])*$/,
            match = text.slice(0, offset)
                .match(pattern);

        if (!match) {
            return null;
        }

        return {
            start: match.index,
            end: offset
        };
    }

    dataCallback = (matchInfo, callback) => {
        const { placeholders } = this.props
        var data = placeholders && placeholders?.filter(function (item) {
            var itemName = '[[' + item.placeholder + ']]';
            return itemName.indexOf(matchInfo.query.toLowerCase()) === 0;
        });

        callback(data);
    }

    render() {
        const { contentDetails, editorKey, isEdit, recordId, contentData, componentKey, edition } = this.props;
        const { isContentLoaded, html } = componentKey === "capabilityQuestionInstruction" ? contentDetails?.[recordId] || {} : contentDetails || {}
        /* CKEditor requires a unique key to re-instantiate*/
        const editorProps = editorKey ? { key: editorKey } : {};

        /*
            CKEditor bug: Does not refresh the content if the same instance is loaded twice with different content
            In Edit mode, the editor instance should not be created until the content is fetched from backend
        */
        const shouldDisplayEditor = (isEdit === true && (recordId !== undefined || isContentLoaded === true)) || isEdit === false;
        const plugins = edition === 'basic' ?
            'easyimage'
            : 'colorbutton,font,justify,colordialog,emoji,print,preview,autocomplete,textmatch,textwatcher,placeholder,easyimage';

        const toolbar = [
            { name: 'document', groups: ['mode', 'document', 'doctools'] },
            { name: 'clipboard', groups: ['clipboard', 'undo'] },
            { name: 'editing', groups: ['find', 'selection', 'spellchecker'] },
            { name: 'forms' },
            { name: 'basicstyles', groups: ['basicstyles', 'cleanup'] },
            { name: 'paragraph', groups: ['list', 'indent', 'blocks', 'align', 'bidi'] },
            { name: 'links' },
            { name: 'insert' },
            { name: 'styles' },
            { name: 'colors' },
            { name: 'tools' },
        ]
        return (
            shouldDisplayEditor &&
            <CKEditor
                onBeforeLoad={(CKEDITOR) => {
                    this.CKEDITOR = CKEDITOR
                    CKEDITOR.addCss('span > .cke_placeholder { background-color: #ffeec2; }');
                    return CKEDITOR.disableAutoInline = true
                }}
                {...editorProps}
                data={recordId !== undefined ? contentData : html}
                type="classic"
                onChange={this.handleChange}
                config={{
                    allowedContent: true,
                    versionCheck: false,
                    toolbarGroups: edition === "basic" && toolbar,
                    extraPlugins: plugins,
                    removePlugins: 'image',
                    //cloudServices_tokenUrl: "https://84998.cke-cs.com/token/dev/1f39f5498e94147b0db99fa9dfcb1931586fb88e59cf0789eb3fc2372de2",
                    cloudServices_tokenUrl: process.env.REACT_APP_USER_SERVICE_API_URL + '/ckeditorTokenService/generateCkeditorToken',
                    cloudServices_uploadUrl: process.env.REACT_APP_CK_EDITOR_UPLOAD_URL,
                    easyimage_toolbar: [
                        'EasyImageFull',
                        'EasyImageAlignLeft',
                        'EasyImageAlignCenter',
                        'EasyImageAlignRight',
                        'EasyImageAlt'
                    ]
                }}
                onInstanceReady={this.handleInstanceReady}

            />
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        contentDetails: state[ownProps.storeKey],
        // isContentLoaded: state[ownProps.storeKey].isContentLoaded,
        demoComponentDetails: state.demoComponentDetails,
        demoAccess: state.demoAccess,
        demoDescription: state.demoDescription,
        ownProps,
        state,
    }
}

export default connect(
    mapStateToProps,
    {
        toggleCkEditorContentChangedFlag
    }
)(TextEditor);