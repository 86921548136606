/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Card, Row, Col, Tag, Tabs, Icon, Divider, Modal, Rate, Form, Popover, Tooltip, Drawer, Spin } from 'antd';
import moment from 'moment-timezone';
import { connect } from 'react-redux';
import { Menu, Select, Table } from "antd5";
import Highlighter from 'react-highlight-words'
import CONFIG from '../../config';
import history from '../../history';
import { getDisplayTime } from '../../utils/status';
import HTML from '../common/HTML'
import { analytics } from '../../firebase';
import DemoReviews from "./DemoReviews";
import EZDemoReviews from "../ManageEZDemos/EZDemoReviews";
import openConfirm from '../common/Confirm';
import EZDemoFeedbackModal from '../common/EZDemoFeedbackModal';
import { toTitleCase, roundToNearestHalf, areArraysEqual } from '../../utils/strings'

import {
    deleteUserRequest, getUpdatedIdString, clearSolutionDemos,
    updateSelectedDemoSolution, postEZDemoFeedback, toggleIsFetchingSolutions, demoRequestDelete, addNewTab, updateActiveTab, closeCurrentOpenTab, openLinkInNewTab, audit, postCapabilityRequest, deleteUserCapabilityRequest,
} from "../../actions";
import { Icon as NeoIcon, Button, Chip } from "@avaya/neo-react";
import { LoadingOutlined } from '@ant-design/icons';
import DemoRegistrationModal from '../common/DemoRegistrationModal';
import CapabilityRequestModal from '../common/CapabilityRequestModal';
const { TabPane } = Tabs;
const { Option } = Select;
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
class DemoCardRef extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            accessVisible: false,
            statusVisible: false,
            reviewsVisible: this.props.params?.isRatingRedirect ? true : false,
            reviewDemoId: undefined,
            readMore: [],
            modalOpen: false,
            currentCapability: {},
            capabilityAnswers: [],
            isView: false,
            currentItem: null,
            ezDemoId: null,
            ezDemoReviewsVisible: false,
            showFeedbackModal: false,
            feedbackAuditId: null,
            currentViewedEZDemo: null,
            height: `${document.body.clientHeight - 150}px`,
            pageRefresh: false,
            pageLoading: false,
            activeKey: this.props.params?.isRatingRedirect ? "reviews" : (this.props.params?.activeTab || "description"),
            registrationModalVisible: false,
        }
        this.pageValue = undefined;
        this.divRef = React.createRef();
        this.cardData = this.createCardData(props.propsDemos[0])
        this.modalRef = React.createRef();
    }

    cardData = {};
    availableDataCenters = []
    unavailableDataCenters = []

    toggleReadMore = (ezDemo) => {
        const id = `${ezDemo?.ezDemo?.id}${ezDemo?.demo?.id}`;
        let modifiedArray = [...this.state.readMore]
        if (this.state.readMore[id] === undefined) { // when user has not clicked for any time
            modifiedArray[id] = true
        }
        else { //when user already clicked
            let value = modifiedArray[id]
            modifiedArray[id] = !value;
        }
        this.setState({
            readMore: modifiedArray
        })
    }

    componentDidMount() {
        const { solutionId } = this.props.params;
        const { dataString } = this.props;
        const selectedData = !!dataString ? [dataString.substring(0, dataString.indexOf('-')), dataString.substring(dataString.indexOf('-') + 1)] : null
        const selectedDemo = (!!selectedData && selectedData?.length > 0) ? this.props.demos?.find(demo => demo?.id === +selectedData[0] && demo?.solutionName === selectedData[1]) : this.props.demos[0];
        this.cardData = this.createCardData(selectedDemo ?? this.props.demos[0]);
        this.pageValue = solutionId;
        window.addEventListener("resize", this.handleResize);
    }

    componentDidUpdate(prevProps, prevState) {
        const { propsDemos } = this.props;
        if (!(areArraysEqual(propsDemos, prevProps?.propsDemos))) {
            this.props.toggleIsFetchingSolutions(true);
            this.cardData = this.createCardData(propsDemos?.length > 0 ? propsDemos[0] : [])
            this.props.toggleIsFetchingSolutions(false);
        }
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleResize);
    }

    handleResize = (e) => {
        const divHeight = this.divRef.current.offsetHeight;
        const windowHeight = document.body.clientHeight;
        const distanceToBottom = windowHeight - divHeight;
        this.setState({ height: distanceToBottom > 250 ? `${distanceToBottom - 150}px` : `250px` });
    }

    expandReadMore = (desc, ezDemo) => {
        const description = desc;
        return (
            <p>
                {this.state.readMore[`${ezDemo?.ezDemo?.id}${ezDemo?.demo?.id}`] ? description : description?.slice(0, 400)}
                <span
                    style={{ fontSize: "14px", color: "#6DB5EC", cursor: "pointer" }}
                    onClick={() => this.toggleReadMore(ezDemo)}
                    className="read-or-hide" >
                    {description?.length > 400 ? this.state.readMore[`${ezDemo?.ezDemo?.id}${ezDemo?.demo?.id}`] ? " show less" : "...read more" : null}
                </span >
            </p>
        );
    }

    showModal = (type) => {
        this.setState({
            [type]: true,
        });
    };

    handleCancel = (type) => {
        this.setState((prevState) => ({
            [type]: false,
            currentCapability: type === "modalOpen" ? {} : prevState.currentCapability,
            isView: type === "modalOpen" ? false : prevState.isView,
            capabilityAnswers: type === "modalOpen" ? [] : prevState.capabilityAnswers
        }));
    };

    handleLinkClick = (item) => {
        item = { ...item, fileName: item?.isFile ? item.link?.match(CONFIG.regEx.getFileName)?.[0] : item?.link }

        this.props.audit(undefined, undefined, undefined, item?.id)

        /* Firebase Analytics Log Event */
        const { demoGuide: {
            name: eventName,
            parameters: eventParams
        } } = CONFIG.firebaseEvents;
        let parameters = {};
        Object.keys(eventParams).forEach(paramKey => {
            parameters[paramKey] = item[eventParams[paramKey]]
        });
        analytics().logEvent(eventName, parameters);
        window.open(item.link, '_blank');
    }


    renderDataCenters = () => {
        const { user } = this.props;
        const userRegion = user.region?.name;
        const timeZoneName = this.props.user ? this.props.user.timezone : "";
        // const showDivider = this.unavailableDataCenters?.length !== 0;
        // const availableDataCenters = [...this.availableGreenDataCenters, ...this.availableYellowDataCenters];
        let userRegionDataCenters = [];
        [...this.availableGreenDataCenters, ...this.availableYellowDataCenters, ...this.unavailableDataCenters].forEach(item => item.dataCenter?.regionsList?.forEach(region => {
            if (region.name === userRegion) userRegionDataCenters.push(item)
        }))
        return [
            this.renderMyRegionDataCenter(userRegionDataCenters, timeZoneName),
            <div>
                <div className='font' style={{ fontWeight: '600', width: "300px", fontFamily: 'Noto Sans', }}>All Data Centers</div><br />
                {[...this.availableGreenDataCenters, ...this.availableYellowDataCenters, ...this.unavailableDataCenters]?.length > 0 ? [...this.availableGreenDataCenters, ...this.availableYellowDataCenters, ...this.unavailableDataCenters]?.map((dataCenter, index) => {
                    return (
                        <div key={index} className='font'>
                            <Row className='font'>
                                <Col className='font' span={8}><span><Icon type={CONFIG.demoStatus.icons[dataCenter.status]} className={CONFIG.demoStatus.colors[dataCenter.status]} /> &nbsp;&nbsp;&nbsp;{dataCenter.dataCenter.title}</span></Col>
                                <Col span={16} className={`data-center-status-desc ${dataCenter.description === null && "center-align font"}`}>{dataCenter.description !== null ? getDisplayTime(dataCenter.description, timeZoneName) : "-"}</Col>
                            </Row>
                            {/* {index === availableDataCenters?.length - 1 } */}
                        </div>
                    );
                }) : <div className='font' align="center"> No data centers available in any region</div>}
            </div>
        ]
    }

    renderMyRegionDataCenter = (userRegionDataCenters, timeZoneName) => {
        return (
            <div>
                <div className='font' style={{ fontWeight: '600', fontFamily: 'Noto Sans', }}>My Region Data Center(s)</div><br />
                {userRegionDataCenters?.length > 0 ? userRegionDataCenters?.map((dataCenter, index) => {
                    return (
                        <div key={index}>
                            <Row className='font'>
                                <Col className='font' span={8}><span><Icon type={CONFIG.demoStatus.icons[dataCenter.status]} className={CONFIG.demoStatus.colors[dataCenter.status]} /> &nbsp;&nbsp;&nbsp;{dataCenter.dataCenter.title}</span></Col>
                                <Col span={16} className={`data-center-status-desc ${dataCenter.description === null && "center-align"}`}>{dataCenter.description !== null ? getDisplayTime(dataCenter.description, timeZoneName) : "-"}</Col>
                            </Row>
                        </div>
                    );
                }) : <div className='font' align="center"> No data centers available in your region</div>}
                <Divider />
            </div>
        )
    }

    renderTitle = (title, size) => {
        const searchString = this.props.searchString
        return (
            <Highlighter
                style={{ fontSize: `${size}px` }}
                searchWords={[searchString]}
                autoEscape={true}
                textToHighlight={title}
            />
        )
    }

    renderStatusRow = () => {
        const { dataCenterStatus } = this.cardData || {};
        const { user } = this.props;
        const userRegion = user.region?.name;
        this.availableGreenDataCenters = dataCenterStatus?.filter(dataCenter => dataCenter.status === CONFIG.demoStatus.dataCenterTypes.green);
        this.availableYellowDataCenters = dataCenterStatus?.filter(dataCenter => dataCenter.status === CONFIG.demoStatus.dataCenterTypes.yellow);
        this.unavailableDataCenters = dataCenterStatus?.filter(dataCenter => dataCenter.status === CONFIG.demoStatus.dataCenterTypes.red);
        let alertType = "error";
        let alertMessage = "Not Available";
        let status = 0;
        let tooltipMessage = "The demo is currently unavailable. Please hover over or click on the demo status for more information.";
        let isGreenDCStatus = true;
        let isYellowDCStatus = true;
        this.availableGreenDataCenters.forEach(item => isGreenDCStatus && item.dataCenter?.regionsList?.every((region) => {
            if (region.name === userRegion) {
                alertType = "success"
                alertMessage = "Available"
                isGreenDCStatus = false;
                status = 2;
                return false
            }
            else {
                alertType = "warning"
                alertMessage = "Available in other regions"
                status = 1
                tooltipMessage = "The demo is currently unavailable in your region but it is available in other regions. Please hover over or click on the demo status for more information."
            }
            return -1
        }))

        if (alertType !== "success") {
            this.availableYellowDataCenters.forEach(item => isYellowDCStatus && item.dataCenter?.regionsList?.every(region => {
                let otherYellowRegionCount = 0;
                otherYellowRegionCount = region.name !== userRegion ? otherYellowRegionCount + 1 : otherYellowRegionCount
                if (region.name === userRegion) {
                    alertType = "warning"
                    alertMessage = "Available"
                    if (otherYellowRegionCount === 0 && this.availableGreenDataCenters?.length === 0) {
                        tooltipMessage = "Some of the demo features might not be available. Please hover over or click on the demo status for more information."
                    }
                    else tooltipMessage = "Some of the demo features might not be available in your region. Please hover over or click on the demo status for more information."
                    isYellowDCStatus = false;
                    status = 1;
                    return false;
                }
                else {
                    alertType = "warning"
                    alertMessage = "Available in other regions"
                    status = 1;
                    tooltipMessage = "The demo is currently unavailable in your region but it is available in other regions. Please hover over or click on the demo status for more information."
                }
                return -1
            }))
        }
        return (
            <div className="demo-status-row font" >
                <Popover placement="left" content={this.renderDataCenters()} id="solution-demo-card-status" >
                    <span onClick={() => dataCenterStatus?.length !== 0 && this.showModal('statusVisible')}>
                        <Tag className={`${CONFIG.demoStatus.tagColors[status].toLowerCase()}-tag`} ><NeoIcon className={`${CONFIG.demoStatus.tagColors[status].toLowerCase()}-tag-color`} size="sm" icon={CONFIG.demoStatus.tagIcons[status]} /><span>{alertMessage}</span></Tag>
                    </span>
                </Popover>
                {alertType !== "success" && <>
                    <Tooltip title={tooltipMessage}>
                        <Icon type="info-circle" className="left-spacing-6 text-color-primary" />
                    </Tooltip>
                </>
                }
            </div>
        )
    }

    renderDownloadsList = () => {
        const { downloads } = this.cardData;
        return downloads?.length > 0 && downloads?.map((collateralType, index) => {
            return (
                <div className='vertical-spacing horizontal-spacing'>
                    <div style={{ gap: "8px", display: "flex", flexDirection: "column" }}>

                        <div> <span className='demo-document-type' >{collateralType?.name}</span></div>
                        {collateralType?.collaterals && collateralType?.collaterals?.length > 0 &&
                            collateralType?.collaterals?.map((collateral) => {
                                const dateString = collateral?.tag?.split(': ');
                                return (<div className='demo-document-div'>
                                    <div className='demo-document-date'>
                                        <span style={{ fontWeight: "600" }}>{dateString[0]}: </span><span style={{ fontWeight: "400" }}>{dateString[1]}</span>
                                    </div>
                                    <div>
                                        <span className="link demo-document-title" onClick={() => this.handleLinkClick(collateral)}>{this.renderTitle(collateral.title, 16)}</span>
                                    </div>
                                    <div>
                                        <span className='demo-document-description'>{this.renderTitle(collateral?.description, 14)}</span>
                                    </div>
                                </div>
                                )
                            })
                        }
                    </div>
                </div>
            );
        })
    }

    handleEZDemoClick = async (ezDemo) => {
        let item = ezDemo?.ezDemo;
        item = { ...item, fileName: item?.isFile ? item.link?.match(CONFIG.regEx.getFileName)?.[0] : item?.link };
        const auditId = await this.props.audit(ezDemo?.ezDemo?.id, ezDemo?.demo?.id, undefined);

        setTimeout(() => {
            this.setState({
                showFeedbackModal: true,
                feedbackAuditId: auditId,
                currentViewedEZDemo: item,
            })
        }, 3000)

        /* Firebase Analytics Log Event */
        const { demoGuide: {
            name: eventName,
            parameters: eventParams
        } } = CONFIG.firebaseEvents;
        let parameters = {};
        Object.keys(eventParams).forEach(paramKey => {
            parameters[paramKey] = item[eventParams[paramKey]]
        });
        analytics().logEvent(eventName, parameters);

        window.open(item.link, '_blank');
    }

    handleFeedbackSubmit = () => {
        this.props.form.validateFields(async (err, values) => {
            if (!err) {
                await this.props.postEZDemoFeedback(this.state.feedbackAuditId, values);
                this.setState({ showFeedbackModal: false, currentViewedEZDemo: null });
            }
        });
    }

    handlePostEZDemoFeedbackSubmit = (response) => {
        const { demo } = this.cardData || [];
        if (response) {
            const currentPath = window.location.pathname;
            const idString = `${demo?.id}-${demo?.solutionName}`;
            this.props.getUpdatedIdString(idString);
            this.handleCancel("modalOpen")
            history.replace("solution/delete/request");
            setTimeout(() => {
                history.replace(currentPath);
            }, 100)
        }
    }

    renderEZDemos = () => {
        const { ezDemos } = this.cardData;
        const { timeZoneName, isEzDemoRatingEnabled, isViewEzDemoCommentEnabled } = this.props;
        return ezDemos?.map((ezDemo) => {
            const tag = ezDemo?.ezDemo?.updatedTime === null
                ? `Created: ${moment.tz(ezDemo?.ezDemo?.createdTime, timeZoneName).format(CONFIG.dateFormats.userDateTime)}`
                : `Updated: ${moment.tz(ezDemo?.ezDemo?.updatedTime, timeZoneName).format(CONFIG.dateFormats.userDateTime)}`

            return (
                <div className='vertical-spacing horizontal-spacing'>
                    <Card key={ezDemo?.ezDemo?.id} type="inner">
                        <div className="ez-demo">
                            <div style={{ fontSize: "14px", color: "#6DB5EC" }}>
                                <span
                                    className="link"
                                    style={{ fontWeight: "500", fontFamily: 'Noto Sans', }}
                                    onClick={() => this.handleEZDemoClick(ezDemo)}
                                >
                                    {ezDemo?.ezDemo?.title}
                                </span>
                                <Tag color="#6DB5EC" className="float-right">{tag}</Tag>
                                {isEzDemoRatingEnabled && <Row>
                                    {(ezDemo?.ezDemo?.ratingCount > 0) && <Rate style={{ color: "#F38D00" }} disabled allowHalf value={roundToNearestHalf(ezDemo?.ezDemo?.rating)} />}
                                    {(ezDemo?.ezDemo?.ratingCount > 0 && isViewEzDemoCommentEnabled) && <span><Divider type="vertical" />
                                        <span
                                            style={{ fontSize: "12px" }}
                                            className={isViewEzDemoCommentEnabled ? "ant-rate-text link" : "ant-rate-text link-disabled"}
                                            onClick={() => isViewEzDemoCommentEnabled ? this.setState({ ezDemoReviewsVisible: true, currentItem: ezDemo?.ezDemo, ezDemoId: ezDemo?.ezDemo?.id }) : undefined}
                                        >
                                            {ezDemo?.ezDemo?.ratingCount} user ratings</span>
                                    </span>}
                                </Row>}
                            </div>
                        </div>

                        <div style={{ fontSize: "12px", color: "#6DB5EC" }}>
                            {this.expandReadMore(ezDemo?.ezDemo?.description, ezDemo)}
                        </div>
                    </Card>
                </div>
            )
        })
    }


    renderScheduleButton = () => {
        const { demo } = this.cardData || [];
        const { isDemoAlreadyRequested, showRequestButton, showScheduleButton, demoType } = demo || false;

        if (showScheduleButton)
            return (<Button
                className="primary-action-button-filled float-right"
                icon='calendar'
                id="schedule-demo-button"
                onClick={() => {
                    const idString = `${demo?.id}-${demo?.solutionName}`;
                    this.props.getUpdatedIdString(idString);
                    this.props.openLinkInNewTab(`Schedule Demo - ${demo?.title}`, demoType === CONFIG.demoTypes.standardDemo ? `/scheduler/standard/${demo?.id}/new` : `/scheduler/lab/${demo?.id}/new`)
                    // history.replace({
                    //     pathname: demoType === CONFIG.demoTypes.standardDemo ? `/ scheduler / standard / ${ demo?.id } / new ` : ` / scheduler / lab / ${ demo?.id } / new `,
                    //     fromDashboard: false,
                    //     pathValues: this.props.pathValues
                    // })
                }}>
                Schedule Demo
            </Button>)

        else if (showRequestButton)
            return (<Button
                className="primary-action-button-filled float-right"
                onClick={() => { this.setState({ registrationModalVisible: true }) }}
            >Request Demo</Button>)
        else if (isDemoAlreadyRequested)
            return (
                <Button disabled={true}
                    animation="none"
                    status="info" className="float-right">
                    Requested
                </Button>
            )
    }

    handleDeleteRequest(demoRequest, demo) {
        const modalTitle = `Would you like to delete this demo request ? `;
        openConfirm("Yes", () => this.handleDelete(demoRequest, demo), null, null, modalTitle, null, "No");
    }

    handleDelete = async (demoRequest, demo) => {
        this.props.toggleIsFetchingSolutions(true);
        const result = await this.props.deleteUserRequest(demoRequest?.id);
        if (result) {
            const currentPath = window.location.pathname
            this.props.toggleIsFetchingSolutions(false);
            await this.props.demoRequestDelete(true);
            const idString = `${demo?.id} - ${demo?.solutionName}`;
            this.props.getUpdatedIdString(idString);
            history.replace("solution/delete/request");
            history.replace(currentPath);
        }
    }

    handleModalOpen = (capability) => {
        this.setState({
            modalOpen: true,
            currentCapability: capability
        })
    }

    getCapabilityQuestions = (capability, submittedCapability) => {
        let capabilityQuestions = [];
        for (let i = 0; i < capability?.questions?.length; i++) {
            capabilityQuestions.push({
                ...capability?.questions[i]?.question,
                ...(submittedCapability?.capabilityAnswers?.find((itmInner) => itmInner?.question?.id === capability?.questions[i]?.question?.id))
            }
            );
        }

        return capabilityQuestions
    }

    getCurrentCapabilityStatus = (allCapabilityRequests, capability) => {
        let currentCapability = allCapabilityRequests?.find(item => item?.capability?.id === capability?.capability?.id);
        if (currentCapability && currentCapability?.capability?.id) return currentCapability;
        else return null
    }

    renderCapabilitiesList = () => {
        const { demoCapabilities, capabilityRequests } = this.cardData;
        const columns = [
            {
                title: <span className='bold'>Title</span>,
                dataIndex: "capability",
                key: "title",
                align: "center",
                width: 200,
                render: (capability) => capability?.title
            },
            {
                title: <span className='bold'>Status</span>,
                key: "status",
                align: "center",
                width: 150,
                render: (capability) => {
                    let status = "-";
                    let tooltipMessage = "Your request was declined. Please refer to the declined email you received or refer to your notifications for further details and action."
                    if (capabilityRequests?.length > 0) {
                        let currentCapability = this.getCurrentCapabilityStatus(capabilityRequests, capability);
                        status = currentCapability?.status || "-"
                    }
                    return (status !== "-" ? <><Tag
                        color={CONFIG.capabilityRequestColors[status]}
                    >
                        {status}
                    </Tag>
                        <span>
                            {status === "Declined" && <>
                                <Tooltip className='capability-tooltip' title={tooltipMessage}>
                                    <Icon type="info-circle" className="left-spacing-6 text-color-primary" />
                                </Tooltip>
                            </>
                            }
                        </span>
                    </> : <Tag color="blue" >No Request</Tag>)
                }
            },
            {
                title: <span className='bold'>Actions</span>,
                align: "center",
                width: 140,
                render: (capability) => {
                    const currentCapability = this.getCurrentCapabilityStatus(capabilityRequests, capability);
                    return (
                        <span>
                            {(currentCapability === undefined || currentCapability === null || currentCapability?.status === CONFIG.capabilityDetailsRequestStatus.Declined) ? <button className='link' onClick={() => this.handleModalOpen(capability?.capability)}>
                                <span class="neo-icon-request-control" title="Request" style={{ fontSize: "20px", color: "#0030ff", marginRight: "15px", cursor: "pointer" }}></span>
                            </button> :
                                <span style={{ marginRight: "15px" }}>-</span>
                            }
                        </span>
                    )
                }

            },

        ]
        return (
            <div>
                <div style={{ paddingTop: "10px", fontSize: "12px" }}>
                    <span style={{ fontWeight: "600", fontFamily: 'Noto Sans', }}>Note: </span>
                    {CONFIG.capabilityMessage}
                </div>
                <br />
                <div
                    style={{ margin: "0px 10px 0px 0px", overflowX: "hidden", paddingBottom: "15px" }}>
                    <Table
                        columns={columns}
                        dataSource={demoCapabilities}
                        pagination={false}
                        size="small"
                        className='font'
                        scroll={{ x: "auto", y: "auto" }}
                    />
                </div>
            </div>
        )
    }

    renderTabs = () => {
        const { description, componentDetails, access, title, ezDemos, demoCapabilities, downloads, averageRating, id } = this.cardData;
        const { user, isViewCommentEnabled, isRatingEnabled } = this.props;
        const isRatingAvailable = averageRating !== 0
        const { demo } = this.cardData;
        const { currentCapability, ezDemoId, currentItem, currentViewedEZDemo } = this.state;
        const userAccess = demo?.userAccess
        const userRoleTemplate = user?.roleTemplate
        // Hot Fix
        const isSfdcUser = user?.roleTemplates?.filter(roleTemplate => roleTemplate.title === CONFIG.sfdcUserTitle)?.length > 0

        const isSuperAdmin = userRoleTemplate.templateType === CONFIG.roleTypes.superAdmin
        const { isRatingRedirect, scheduleId, demoId, isLowRatingAlert } = this.props?.params || []
        const { showScheduleButton } = demo || null;
        const tabHeight = `${parseInt(this.state.height, 10) - 150}px`;
        return (
            <React.Fragment>
                <Tabs
                    hideAdd
                    onChange={(e) => this.setState({ activeKey: e })}
                    activeKey={(this.state.activeKey ? this.state.activeKey?.includes(",") ? "description" : this.state.activeKey : "description") || "description"}
                    size="small"
                    type='card'
                    style={{
                        marginLeft: "5px", marginRight: "10px", paddingBottom: "10px",
                    }}
                    className="demo-tabs"

                >
                    <TabPane
                        className="demo-tab-pane scrollable-tab-content"
                        style={{ maxHeight: this.state.height, overFlowY: "auto" }}
                        tab={<span
                            ref={this.divRef}
                            style={{ fontSize: "13px" }}>Details</span>}
                        key="description">
                        {description !== null ?
                            <span style={{ height: tabHeight, display: "block" }} className='font'><br /><HTML className="font" htmlContent={description} /> <br /></span>
                            : <span style={{ height: tabHeight, display: "block" }}><div className='dashboard-no-schedules'><NeoIcon icon="info" size='md' />No Details</div></span>
                        }
                    </TabPane>
                    <TabPane
                        className="demo-tab-pane scrollable-tab-content"
                        style={{ maxHeight: this.state.height, overflowY: "auto" }}
                        tab={<span style={{ fontSize: "13px" }}>Components</span>}
                        key="components">
                        {componentDetails !== null ?
                            <span style={{ height: tabHeight, display: "block" }} className='font'><br /> <HTML htmlContent={componentDetails} /> <br /></span>
                            : <span style={{ height: tabHeight, display: "block" }}><div className='dashboard-no-schedules'><NeoIcon icon="info" size='md' />No Components</div></span>
                        }
                    </TabPane>
                    {
                        (showScheduleButton || isSfdcUser) &&
                        <TabPane
                            className="demo-tab-pane scrollable-tab-content"
                            style={{ maxHeight: this.state.height, overflowY: "auto" }}
                            tab={<>
                                <span
                                    style={{ marginRight: "5px" }}
                                    onClick={async () => {
                                        this.props.openLinkInNewTab(CONFIG.editComponentRoute.demoAccess.title + " " + title, CONFIG.editComponentRoute.demoAccess.key + demo?.id)
                                    }}>
                                    <NeoIcon icon="screenpop-on" style={{ color: "#1B77AF", fontSize: "18px" }} />
                                </span>
                                <span style={{ fontSize: "13px" }}>
                                    Access &nbsp;
                                </span>
                            </>}
                            key="access">
                            {access !== null ?
                                <span style={{ height: tabHeight, display: "block" }} className='font'><br />
                                    <HTML htmlContent={access} />
                                    <br /></span>
                                : <span style={{ height: tabHeight, display: "block" }}><div className='dashboard-no-schedules'><NeoIcon icon="info" size='md' />No Access Information</div></span>
                            }
                        </TabPane>
                    }
                    {
                        (isSuperAdmin || userAccess === CONFIG.demoAccess.fullAccess || isSfdcUser) &&
                        <TabPane
                            className="demo-tab-pane scrollable-tab-content"
                            style={{ maxHeight: this.state.height, overflowY: "auto" }}
                            tab={<span style={{ fontSize: "13px" }}>Documents</span>}
                            key="downloads">
                            {downloads && downloads?.length > 0 ? <span style={{ height: tabHeight, display: "block" }}>{this.renderDownloadsList(tabHeight)}</span> : <span style={{ height: tabHeight, display: "block" }}> <div className='dashboard-no-schedules'><NeoIcon icon="info" size='md' />No Documents</div></span>}
                        </TabPane>
                    }
                    {
                        ezDemos?.length > 0 &&
                        <TabPane
                            className="demo-tab-pane scrollable-tab-content"
                            style={{ maxHeight: this.state.height, overflowY: "auto" }}
                            tab={<span style={{ fontSize: "13px" }}>EZ Demos</span>}
                            key="ezDemos">
                            <span style={{ height: tabHeight, display: "block" }}>{this.renderEZDemos()}</span>
                        </TabPane>
                    }
                    {
                        demoCapabilities?.length > 0 &&
                        <TabPane
                            tab={<span style={{ fontSize: "13px" }}>Capability Request</span>}
                            className="demo-tab-pane scrollable-tab-content"
                            style={{ maxHeight: this.state.height, overflowY: "auto" }}
                            key="capabilities">
                            <span style={{ height: tabHeight, display: "block" }}>{this.renderCapabilitiesList()}</span>
                        </TabPane>
                    }
                    {
                        (isRatingEnabled && isRatingAvailable && isViewCommentEnabled) &&
                        <TabPane
                            tab={<span style={{ fontSize: "13px" }}>User Ratings</span>}
                            className="demo-tab-pane scrollable-tab-content"
                            style={{ maxHeight: this.state.height, overflowY: "auto" }}
                            key="reviews">
                            <span style={{ height: tabHeight, display: "block" }}>
                                <DemoReviews
                                    reviewDemoId={demoId ?? id}
                                    isRatingRedirect={isRatingRedirect}
                                    scheduleId={scheduleId}
                                    demoId={demoId ?? id}
                                    isLowRatingAlert={isLowRatingAlert}
                                />
                            </span>
                        </TabPane>
                    }
                </Tabs>
                {this.state.ezDemoReviewsVisible && <Drawer
                    width="75%"
                    placement="right"
                    title={"EZ Demo Ratings - " + currentItem?.title}
                    headerStyle={{ padding: '20px' }}
                    visible={this.state.ezDemoReviewsVisible}
                    onClose={() => this.setState({ ezDemoReviewsVisible: false, currentItem: null, ezDemoId: null })}
                    closable={true}
                > <EZDemoReviews
                        ezDemoId={ezDemoId}
                    />
                </Drawer>}
                {this.state.showFeedbackModal && <EZDemoFeedbackModal
                    showFeedbackModal={this.state.showFeedbackModal}
                    feedbackAuditId={this.state.feedbackAuditId}
                    fetchOnMount={this.fetchOnMount}
                    title={currentViewedEZDemo?.title}
                    handleCancel={() => this.setState({ showFeedbackModal: false })}
                    handlePostFeedbackSubmitOnDemoCard={this.handlePostEZDemoFeedbackSubmit}
                />}

                {this.state.modalOpen && <CapabilityRequestModal
                    data={currentCapability}
                    visible={this.state.modalOpen}
                    handleCancel={() => this.handleCancel("modalOpen")}
                    isEdit={false}
                    demo={this.cardData?.demo}
                    handlePostFeedbackSubmit={this.handlePostEZDemoFeedbackSubmit}
                />}
            </React.Fragment >
        );
    }

    groupByCollateralType = (demo) => {
        const { collaterals } = demo || [];
        const timeZoneName = this.props.user ? this.props.user.timezone : "";
        let addedCollateralTypes = [];
        let collateralTypes = [];
        if (collaterals && collaterals?.length > 0) {
            collaterals.forEach(collateral => {
                let foundCollateralIndex = undefined;
                let newCollateral = {
                    id: collateral.id,
                    title: collateral.title,
                    link: collateral.link,
                    description: collateral.description,
                    tag: collateral.updatedTime === null
                        ? `Created: ${moment.tz(collateral.createdTime, timeZoneName).format(CONFIG.dateFormats.userDateTime)} `
                        : `Updated: ${moment.tz(collateral.updatedTime, timeZoneName).format(CONFIG.dateFormats.userDateTime)} `,
                    isNew: collateral.new,
                };
                if (!addedCollateralTypes.includes(collateral.collateralType.id)) {
                    addedCollateralTypes.push(collateral.collateralType.id);
                    collateralTypes.push({
                        id: collateral.collateralType.id,
                        name: collateral.collateralType.name,
                        collaterals: [newCollateral]
                    });
                }
                else {
                    foundCollateralIndex = collateralTypes.findIndex(collateralType => collateralType.id === collateral.collateralType.id);
                    collateralTypes[foundCollateralIndex].collaterals.push(newCollateral);
                }
            });
            return collateralTypes;
        }
        else return []
    }

    createCardData = (demo) => {
        return {
            id: demo.id,
            title: demo.title,
            description: demo.description,
            componentDetails: demo.componentDetails,
            access: demo.connectivityDetails,
            accessUpdatedTime: demo.accessUpdatedTime,
            updatedTime: demo.updatedTime,
            createdTime: demo.createdTime,
            isNew: demo.isNew,
            dataCenterStatus: demo?.activeDataCenters?.flatMap(dataCenter =>
                dataCenter.active ? (demo.demoDCStatuses.find(demoDataCenter => demoDataCenter.dataCenter.id === dataCenter.id) ?
                    { ...demo.demoDCStatuses.find(demoDataCenter => demoDataCenter.dataCenter.id === dataCenter.id), dataCenter: dataCenter }
                    : { dataCenter: dataCenter, description: null, status: CONFIG.demoStatus.default })
                    : []) || [],
            solutionName: demo.solutionName,
            downloads: this.groupByCollateralType(demo),
            activeTab: demo.activeTab,
            averageRating: demo.rating,
            ratingCount: demo.ratingCount,
            commentCount: demo.commentCount,
            ezDemos: demo.demoEzDemos,
            demoCapabilities: demo.demoCapabilities,
            capabilityRequests: demo.capabilityRequests,
            demoType: demo.demoType,
            notes: demo?.notes,
            demo
        };
    }

    renderMenu = (demo) => {
        const { title, solutionName, isNew, demoType } = demo || {};
        return (
            <div className="menu-demo-container">
                <div className='solution-name-title'>
                    {this.props.developerMode && <span style={{ marginRight: "10px" }}><NeoIcon icon="stop-filled" style={{ color: `${demoType === CONFIG.demoTypes.standardDemo ? `#4169E1` : demoType === CONFIG.demoTypes.labPocDemo ? `#32a852` : `#DC143C`} ` }} /></span>}
                    {solutionName}
                    {isNew && <span className='float-right'>
                        <Chip variant="info" style={{ fontWeight: "400", fontSize: "12px", fontFamily: 'Noto Sans', marginRight: "2px" }} >New</Chip>
                    </span>}
                </div>
                <div title={title} className='demo-menu-title margin-bottom-16'>
                    {title}
                </div>
            </div>
        )
    }

    renderDemosListMenu = () => {
        return (
            this.props.demos?.map(demo => {
                return (
                    <Menu.Item
                        className='font'
                        onClick={({ key }) => {
                            this.setState({ pageLoading: true })
                            const [id, solutionName] = key.split('\\');
                            const currentSelectedDemo = this.props.demos?.find(demo => ((demo?.id === parseInt(id)) && (demo?.solutionName?.toLowerCase()?.trim() === solutionName?.toLowerCase()?.trim())));
                            const demoData = this.createCardData(currentSelectedDemo);
                            this.cardData = demoData;
                            this.props.getUpdatedIdString(`${id} -${solutionName} `);
                            setTimeout(() => {
                                this.setState((prevState) => (({ pageRefresh: !prevState.pageRefresh, pageLoading: false, activeKey: "description" })))
                            }, 500)
                        }}
                        style={{ paddingLeft: "10px", borderBottom: "1px solid #C9C9C9", borderRadius: "0px", paddingRight: "0px" }}
                        key={(`${demo?.id} \\${demo?.solutionName} `)}
                    >
                        {this.renderMenu(demo)}
                    </Menu.Item >
                )
            })
        )
    }

    renderDemoDetails = () => {
        const { updatedTime, createdTime, ratingCount, averageRating, demoType, title, notes, solutionName } = this.cardData;
        const timeZoneName = this.props.user ? this.props.user.timezone : "";
        const { developerMode } = this.props;
        const startUTC = moment(createdTime).tz(timeZoneName, true).utc();
        const utcNow = moment().utc();
        const shouldDisplayCreatedTime = moment(createdTime).isSame(moment(updatedTime)) || !updatedTime;
        const createdTag = utcNow.diff(startUTC, 'days') < CONFIG.newDemoDuration || shouldDisplayCreatedTime ? `${moment.tz(createdTime, timeZoneName).format(CONFIG.dateFormats.userDateTime)} ` : undefined;
        const updatedTag = !shouldDisplayCreatedTime ? `${moment.tz(updatedTime, timeZoneName).format(CONFIG.dateFormats.userDateTime)} ` : undefined;
        const { isRatingEnabled, isViewCommentEnabled } = this.props;
        const { statusVisible } = this.state;
        const isRatingAvailable = averageRating !== 0;

        return (
            <div className='demo-details-menu'>
                <div className='user-solution-type'>
                    {solutionName}
                    {developerMode && <span style={{ marginLeft: "10px" }}><Tag color='geekblue'>{demoType}</Tag></span>}
                </div>
                <div style={{ width: "100%", marginBottom: "14px" }}>
                    <span className='demo-card-title'>
                        {title}
                    </span>
                    {demoType === CONFIG.demoTypes.standardDemo && <span className='float-right'>
                        {this.renderStatusRow()}
                        <Modal
                            title="Datacenter Status"
                            visible={statusVisible}
                            onCancel={() => this.handleCancel('statusVisible')}
                            footer={null}
                        >
                            {this.renderDataCenters()}
                        </Modal>

                    </span>}
                    <div style={{ fontSize: "12px" }} >
                        {notes}
                    </div>
                </div>
                <div style={{ width: "100%" }}>
                    {this.renderScheduleButton()}
                    {createdTag && <div>
                        <span className='demo-ratings'>Created: </span><span style={{ fontSize: "12px" }}>{createdTag}</span>
                    </div>}
                    {updatedTag && <div>
                        <span className='demo-ratings'>Demo Last Updated: </span><span style={{ fontSize: "12px" }}>{updatedTag}</span>
                    </div>}
                </div>
                <div style={{ width: "100%" }}>
                    {isRatingEnabled && <>
                        {isRatingAvailable && <span className='demo-ratings'>User Rating:<Rate style={{ color: "#F38D00", marginLeft: '10px' }} disabled allowHalf value={roundToNearestHalf(averageRating)} /></span>}
                        {(isRatingAvailable && isViewCommentEnabled) && <span><Divider type="vertical" />
                            <span className="link" onClick={() => this.setState({ activeKey: "reviews" })} style={{ fontSize: "12px" }} >{ratingCount} user ratings</span>
                        </span>}

                    </>}

                </div>
            </div>
        )
    }

    handleSelectChange = async (value) => {
        const solution = JSON.parse(value);
        const solutionTypeId = parseInt(this.pageValue);
        // // this.refs.demoSearch.input.state.value = '';
        this.props.clearSolutionDemos();
        this.props.toggleIsFetchingSolutions(true);
        await this.props.updateSelectedDemoSolution(solution, solutionTypeId);
    }

    renderSolutionsOptions = () => {
        if (this.props.solutions) {
            return this.props.solutions.map((solution, index) => {
                return solution.demos && solution.demos.length !== 0
                    ? <Option style={{ fontFamily: 'Noto Sans' }} key={index} value={JSON.stringify(solution)}>{solution.title}</Option>
                    : undefined
            });
        }
        return [];
    };

    handleDemoRequestSubmit = () => {
        this.setState({
            registrationModalVisible: false
        });
        const { demo } = this.cardData;
        const currentPath = window.location.pathname;
        const idString = `${demo?.id} -${demo?.solutionName} `;
        this.props.getUpdatedIdString(idString);
        history.replace("solution/delete/request");
        history.replace(currentPath);
    }

    render() {
        const { selectedSolution, openedTabs, activeKey } = this.props;
        const { solutionType, demoId, solutiontypeId } = this.props.params;
        const fromDashboard = demoId !== undefined;
        const isAllDemosSearch = solutiontypeId !== undefined;
        const searchTitle = openedTabs.find(item => item.key === activeKey)?.title || toTitleCase((solutionType || ""));
        return (
            <Row className='font'>
                <Col xl={6}
                >
                    {(!isAllDemosSearch && !fromDashboard) && <div style={{ margin: "0 8px 10px 5px" }}>
                        {this.props.developerMode && <div>
                            <span className='right-spacing-3'><NeoIcon icon="stop-filled" style={{ color: '#4169E1' }} /> Standard</span>
                            <span className='right-spacing-3'><NeoIcon icon="stop-filled" style={{ color: '#32a852' }} /> Lab & PoC</span>
                            <span className='right-spacing-3'><NeoIcon icon="stop-filled" style={{ color: '#DC143C' }} /> Restful</span>
                        </div>}
                        <Select
                            label="Filter by type"
                            multiple
                            searchable
                            value={selectedSolution === CONFIG.ALL ? selectedSolution : JSON.stringify(selectedSolution)}
                            onChange={this.handleSelectChange}
                            style={{ fontFamily: 'Noto Sans' }}
                        >
                            {<Option style={{ fontFamily: 'Noto Sans' }} key={Date.now()} value={CONFIG.ALL}>{`All ${searchTitle} Demos`}</Option>}
                            {this.renderSolutionsOptions()}
                        </Select>
                    </div>}
                    <div
                        style={{ overflowY: "auto", height: this.state.height }}
                    >
                        <Menu
                            className='demo-menu'
                            selectedKeys={(`${this.cardData?.id} \\${this.cardData?.solutionName} `)}
                        >
                            {this.renderDemosListMenu()}
                        </Menu>

                    </div>
                </Col>
                <Spin indicator={antIcon} className='flexbox-center' style={{ left: "12%" }} spinning={this.state.pageLoading}>
                    <Col xl={18}
                        className='gray-col-background'
                    >
                        {!this.state.pageLoading && <div>
                            {this.renderDemoDetails()}
                            <div>
                                {this.renderTabs()}
                            </div>
                        </div>}
                    </Col>
                </Spin>
                {
                    this.state.registrationModalVisible &&
                    <DemoRegistrationModal
                        visible={this.state.registrationModalVisible}
                        handleDemoRequestSubmit={this.handleDemoRequestSubmit}
                        demo={this.cardData?.demo}
                        isEdit={false}
                        handleCancel={() => this.setState({ registrationModalVisible: false })}
                    />
                }
            </Row>
        );
    }
}

const mapStateToProps = ({ viewport, user, dataCenters, tabsLayout, demoSolutions, solutions }) => {
    return {
        viewport,
        user: user.profile,
        allDataCenters: dataCenters.data,
        isRatingEnabled: user.isRatingEnabled,
        isViewCommentEnabled: user.isViewCommentEnabled,
        isEzDemoRatingEnabled: user.isEzDemoRatingEnabled,
        isViewEzDemoCommentEnabled: user.isViewEzDemoCommentEnabled,
        openedTabs: tabsLayout.panes,
        activeKey: tabsLayout.activeKey,
        previousTabs: tabsLayout.previousTabs,
        timeZoneName: user.profile ? user.profile.timezone : undefined,
        dataString: demoSolutions.dataString,
        demos: demoSolutions.demos,
        solutions: solutions.data,
        selectedSolution: demoSolutions.selectedSolution,
        developerMode: user.profile?.isDeveloper,
    };
};

const DemoCard = Form.create()(DemoCardRef);

export default connect(
    mapStateToProps,
    {
        toggleIsFetchingSolutions,
        deleteUserRequest,
        demoRequestDelete,
        addNewTab,
        updateActiveTab,
        closeCurrentOpenTab,
        openLinkInNewTab,
        audit,
        postCapabilityRequest,
        deleteUserCapabilityRequest,
        getUpdatedIdString,
        postEZDemoFeedback,
        clearSolutionDemos,
        updateSelectedDemoSolution,
    }
)(DemoCard);